export const tableColumnsTopCreators = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "LOC Usage",
    accessor: "loc-usage",
  },
  {
    Header: "Percentage",
    accessor: "percentage",
  },
];