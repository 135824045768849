import React, { useState, useEffect } from "react";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";

import { FaEthereum } from "react-icons/fa";
import Card from "components/card";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

const HistoryCard = () => {

  const [blogArray, setBlogArray] = useState([]);
    const navigate = useNavigate("");

    useEffect((e) => {
        fetchBlogs(e);
    }, []);

    const fetchBlogs = async (e) => {
        try {
            const url = "https://api.cybersanctus.com/api/v1/blogs";
            const blogsData = await axios.get(url);
            setBlogArray(blogsData.data.data.blogs)
        } catch (error) {
            //hidden console.log("error");
        }
    };

  return (
    <Card extra={"mt-3 !z-5 overflow-hidden"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          News & Blogs
        </div>
        <button onClick={() => window.open('https://cybersanctus.com/blog')} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          See all
        </button>
      </div>

      {/* History CardData */}

      {blogArray.slice(0).reverse().map((blog, index) => (
        <div onClick={() => window.open(`https://cybersanctus.com/blog/${blogArray.slice(0).reverse()[index]._id}`)} className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          <div className="flex items-center gap-3">
          {/* style={{ backgroundImage: `url(${blog.photo})`, aspectRatio: '1 / 1' }} */}
            <div className="flex h-16 w-16 items-center justify-center object-cover	">
              <img
                className="h-full w-full rounded-xl aspect-square object-cover"
                src={blog.photo}
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-bold text-navy-700 dark:text-white">
                {" "}
                {/* {blog.title} */}
                {blog.title.substring(0,20) + "..."}
              </h5>
              <p className="mt-1 text-sm font-normal text-gray-600">
                {" "}
                {"CyberSanctus Team"}{" "}
              </p>
            </div>
          </div>

          <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
            <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
              <p>{moment.utc(blog.createdAt).local().startOf('seconds').fromNow()}</p>
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};

export default HistoryCard;
