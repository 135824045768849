import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import React from "react";
import { BsCloudCheck } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';

const GitHubIcon = require('../../../../src/assets/svg/GitHub.svg').default
const GitHubIconRounded = require('../../../../src/assets/svg/GithubIcon.svg').default

const Storage = () => {

  const loginWithGitHub = () => {
    // const clientID = 'Iv23liB9Oy48Hl6E1Ucz';
    // const redirectURI = 'http://localhost:3000/admin/default';
    // window.open(`https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}`);
    toast.warn("This feature is under maintainance.");
  };

  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="ml-auto">
        {/* <CardMenu /> */}
      </div>
      {/* Your storage */}
      <div className="mb-auto flex flex-col items-center justify-center text-center">
        {/* <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
          <BsCloudCheck />
        </div> */}
        <img src={GitHubIconRounded} width="50%"/>
        <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
          Connect to GitHub for simpler scans
        </h4>
        <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
          Connect to GitHub to allow CodeHound to access your projects more easily.
        </p>
      </div>

      {/* Progress bar */}

        <button onClick={loginWithGitHub}
            className="linear flex flex-row items-center rounded-xl bg-gradient-to-br from-brandSubtle to-brandStrong px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-brand-500/50"
            data-ripple-light
            >
            {/* <svg
                className="mr-2 fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                height="16"
                width="16"
            >
                <path d="M18.5 5.146V14.854Q18.5 15.688 17.927 16.26Q17.354 16.833 16.521 16.833H3.479Q2.646 16.833 2.073 16.26Q1.5 15.688 1.5 14.854V5.146Q1.5 4.312 2.073 3.74Q2.646 3.167 3.479 3.167H16.521Q17.354 3.167 17.927 3.74Q18.5 4.312 18.5 5.146ZM3.479 6.625H16.521V5.146Q16.521 5.146 16.521 5.146Q16.521 5.146 16.521 5.146H3.479Q3.479 5.146 3.479 5.146Q3.479 5.146 3.479 5.146ZM3.479 10.062V14.854Q3.479 14.854 3.479 14.854Q3.479 14.854 3.479 14.854H16.521Q16.521 14.854 16.521 14.854Q16.521 14.854 16.521 14.854V10.062ZM3.479 14.854Q3.479 14.854 3.479 14.854Q3.479 14.854 3.479 14.854V5.146Q3.479 5.146 3.479 5.146Q3.479 5.146 3.479 5.146Q3.479 5.146 3.479 5.146Q3.479 5.146 3.479 5.146V14.854Q3.479 14.854 3.479 14.854Q3.479 14.854 3.479 14.854Z" />
            </svg> */}
            <img src={GitHubIcon} width={20} style={{'marginRight': '10px'}}/>
            Connect to GitHub
        </button>

      {/* <div className="flex flex-col">
        <div className="flex justify-between">
          <p className="text-sm font-medium text-gray-600">25.6 GB</p>
          <p className="text-sm font-medium text-gray-600">50 GB</p>
        </div>
        <div className="mt-2 flex h-3 w-full items-center rounded-full bg-lightPrimary dark:!bg-navy-700">
          <span className="h-full w-1/2 rounded-full bg-brand-500 dark:!bg-white" />
        </div>
      </div> */}
    </Card>
  );
};

export default Storage;
