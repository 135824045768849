export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "CONTRACT",
    accessor: "name",
  },
  {
    Header: "SCORE",
    accessor: "score",
  },
  {
    Header: "ID",
    accessor: "loc",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "REPORT",
    accessor: "report",
  },
];

export const columnsDataComplex = [
  {
    Header: "Contract",
    accessor: "name",
  },
  {
    Header: "Score",
    accessor: "score",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Report",
    accessor: "report",
  }
];
