import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import Switch from "components/switch";
import React from "react";

function Notification(props) {
  const { type } = props;
  return (
    <Card extra={"w-full h-full p-3"}>
      <div className="relative mb-3 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {type}
        </h4>
        <CardMenu />
      </div>

      {type === "Scan Options" ? 
          <div className="flex flex-col">
            <div className="mt-3 flex items-center gap-3">
              <Switch id="switch1" disabled defaultChecked />
              <label
                for="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Include High Severity Detections
              </label>
            </div>

            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch2" disabled defaultChecked />
              <label
                for="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Include Medium Severity Detections
              </label>
            </div>

            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch3" disabled defaultChecked />
              <label
                for="checkbox3"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Include Low Severity Detections
              </label>
            </div>

            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch4" disabled defaultChecked />
              <label
                for="checkbox4"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Include Informational Detections
              </label>
            </div>

            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch5" disabled />
              <label
                for="checkbox5"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Include Gas Optimisations
              </label>
            </div>
          </div>
        
          :

          <div className="flex flex-col" >
            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch7" disabled />
              <label
                for="checkbox7"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Monthly product changes
              </label>
            </div>
            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch8" disabled />
              <label
                for="checkbox8"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Subscribe to newsletter
              </label>
            </div>
            <div className="mt-4 flex items-center gap-3">
              <Switch id="switch8" disabled />
              <label
                for="checkbox8"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Email me about discounts and promotions
              </label>
            </div>
          </div>
        }
    </Card>
  );
}

export default Notification;
