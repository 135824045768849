import CryptoTracker from "views/default/components/CryptoTracker";
import PieChartCard from "views/default/components/PieChartCard";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard, MdDataUsage } from "react-icons/md";
import { TbTextScan2 } from "react-icons/tb";
import { PiListMagnifyingGlassBold } from "react-icons/pi";
import { IoFlash } from "react-icons/io5";
import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import { useCookies } from "react-cookie";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Banner from "../marketplace/components/Banner";
import Storage from "./components/Storage";
import ConnectGitHub from './components/ConnectGitHub'

import Widget from "components/widget/Widget";
import ComplexTable from "views/default/components/ComplexTable";
import DailyTraffic from "views/default/components/DailyTraffic";


const Dashboard = ({ userData, scanData, planData }) => {

  const platinumBadge = require("../../../src/assets/img/platinum.png")
  const goldBadge = require("../../../src/assets/img/gold.png")
  const silverBadge = require("../../../src/assets/img/silver.png")
  const bronzeBadge = require("../../../src/assets/img/bronze.png")

  const [userPlan, setUserPlan] = useState({"name": "Newbie"});
  const [formattedScans, setFormattedScans] = useState([]);
  const [cookies] = useCookies(["cookie-name"]);
  const [totFindings, setTotFindings] = useState(0);
  const [overallSecScore, setOverallSecScore] = useState(0);
  const [ratingsArray, setRatingsArray] = useState([0,0,0]);

  useEffect((e) => {
    let customArray = [];
    let tempFindings = 0;
    let cumulativeSecScore = 0;
    let highVulns = 0;
    let medVulns = 0;
    let restVulns = 0;
    scanData.map((scan) => {
      tempFindings = tempFindings + scan.vulns.length;
      let localSecScore = 0;

      const date = new Date(scan.createdAt);
      const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);

      scan.vulns.map((vuln) => {
        // //hidden console.log(vuln)
        // IMPLEMENT LOGIC TO CALCULATE SECURITY SCORE
        if(vuln.rating === "High"){
          cumulativeSecScore = cumulativeSecScore + 8;
          localSecScore = localSecScore + 8;
          highVulns = highVulns + 1;
        } else if(vuln.rating === "Medium"){
          cumulativeSecScore = cumulativeSecScore + 4;
          localSecScore = localSecScore + 4;
          medVulns = medVulns + 1;
        } else{
          cumulativeSecScore = cumulativeSecScore + 2;
          localSecScore = localSecScore + 2;
          restVulns = restVulns + 1;
        }
      })

      customArray.push({
        name: 'default scan',
        date: formattedDate,
        lines: scan.lines,
        id: scan._id.slice(-4),
        score: 10 - (localSecScore / scan.vulns.length),
        report: scan.docUrl
      });
    })
    setFormattedScans(customArray);
    setTotFindings(tempFindings);
    setOverallSecScore(10 - (cumulativeSecScore / tempFindings));
    setRatingsArray([highVulns,medVulns,restVulns]);
  }, [scanData])

  useEffect(() => {
    if(userData.data.user.purchasedPackage){
      identifyPlan(userData.data.user.purchasedPackage.package);
    }
  }, [planData])


  const identifyPlan = async (planid) => {
    if(planData){
      planData.map((plan) => {
        if(planid === plan._id){
          setUserPlan(plan);
        }
      })
    }
  }

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Subscription"}
          subtitle={userPlan.name}
        />
        <Widget
          icon={<MdDataUsage className="h-6 w-6" />}
          title={"Montly Lines"}
          subtitle={userData.data.user.purchasedPackage ? userData.data.user.purchasedPackage.remainingLines : "N/A"}
        />
        <Widget
          icon={<IoFlash className="h-6 w-6" />}
          title={"Instant Lines"}
          subtitle={userData.data.user.instantLines}
        />
        {/* <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Avg. Score"}
          subtitle={Math.round(overallSecScore * 100)/100}
        /> */}
        <Widget
          icon={<TbTextScan2 className="h-7 w-7" />}
          title={"Scans Complete"}
          subtitle={`${scanData.length}`}
        />
        <Widget
          icon={<PiListMagnifyingGlassBold className="h-6 w-6" />}
          title={"Tot. Findings"}
          subtitle={`${totFindings}`}
        />
        <Widget
          icon={<img src={overallSecScore >= 9.0 ? platinumBadge : (overallSecScore >= 8.0 ? goldBadge : (overallSecScore >= 6.0 ? silverBadge : bronzeBadge))} className="h-7 w-7" />}
          title={"Sec. Score"}
          subtitle={overallSecScore >= 9.0 ? "CST A+" : (overallSecScore >= 8.0 ? "CST B+" : (overallSecScore >= 6.0 ? "CST B" : "CST C"))}
        />
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

     <div className="mt-5 grid-cols-1 gap-5">
      <Banner bannerSize="Large" />
     </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">

        <CryptoTracker />

        <ComplexTable
          columnsData={columnsDataComplex}
          // tableData={tableDataComplex}
          tableData={formattedScans}
          paginationBar={false}
        />

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          {scanData ? <DailyTraffic scanData={scanData}/> : null}
          
          <PieChartCard ratingsArray={ratingsArray}/>
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <Storage userData={userData} planData={planData} />
          <ConnectGitHub />
          {/* <TopCreatorTable
            extra="mb-5"
            tableData={tableDataTopCreators}
            columnsData={tableColumnsTopCreators}
          /> */}
        </div>


        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
