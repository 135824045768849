import { useDisclosure } from "@chakra-ui/hooks";
import React, { useRef, useState, useEffect } from 'react';
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "../default/components/Storage";
import Upload from "./components/Upload";
import axios from 'axios';
import { useCookies } from "react-cookie";


const ProfileOverview = ({ userData, scanData, planData }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = useRef()
  const [cookies] = useCookies(["cookie-name"]);
  const [overallSecScore, setOverallSecScore] = useState(0);
  const [formattedScans, setFormattedScans] = useState([]);
  const [totFindings, setTotFindings] = useState(0);

  useEffect((e) => {
    let customArray = [];
    let tempFindings = 0;
    let cumulativeSecScore = 0;
    scanData.map((scan) => {
      tempFindings = tempFindings + scan.vulns.length;
      let localSecScore = 0;

      const date = new Date(scan.createdAt);
      const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);

      scan.vulns.map((vuln) => {
        // //hidden console.log(vuln)
        // IMPLEMENT LOGIC TO CALCULATE SECURITY SCORE
        if(vuln.rating === "High"){
          cumulativeSecScore = cumulativeSecScore + 8;
          localSecScore = localSecScore + 8;
        } else if(vuln.rating === "Medium"){
          cumulativeSecScore = cumulativeSecScore + 4;
          localSecScore = localSecScore + 4;
        } else{
          cumulativeSecScore = cumulativeSecScore + 2;
          localSecScore = localSecScore + 2;
        }
      })

      customArray.push({
        name: 'default scan',
        date: formattedDate,
        lines: scan.lines,
        id: scan._id.slice(-4),
        score: 10 - (localSecScore / scan.vulns.length),
        report: scan.docUrl
      });
    })
    setFormattedScans(customArray);
    setTotFindings(tempFindings);
    setOverallSecScore(10 - (cumulativeSecScore / tempFindings));
  }, [scanData])

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner userData={userData} planData={planData} scanData={scanData} secScore={Math.round(overallSecScore * 100)/100}/>
        </div>
        <div className="col-span-3 lg:!mb-0">
          <Storage userData={userData} planData={planData}/>
        </div>

        <div className="z-0 col-span-5 lg:!mb-0">
          {/* <Upload /> */}
          <General userData={userData}/>
        </div>
      </div>
      {/* all project & ... */}

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">

        {/* col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3 */}
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <Notification type="Scan Options" />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <Notification type="Preferences" />
        </div>
      </div>

      <div className="flex justify-center">
        <button className="rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-red-400 dark:hover:bg-red-300/10 dark:active:bg-red-200/10">
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default ProfileOverview;
