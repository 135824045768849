import Banner from "./components/Banner";

import ComplexTable from "views/default/components/ComplexTable";
import tableDataComplex from "../default/variables/tableDataComplex.json";

import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";

import CryptoTracker from "views/default/components/CryptoTracker";
import PieChartCard from "views/default/components/PieChartCard";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDataUsage } from "react-icons/md";
import { TbTextScan2 } from "react-icons/tb";
import { PiListMagnifyingGlassBold } from "react-icons/pi";
import { IoFlash } from "react-icons/io5";
import { PiContactlessPaymentFill } from "react-icons/pi";

import Widget from "components/widget/Widget";
import DailyTraffic from "views/default/components/DailyTraffic";

import PricingCard from "./components/PricingCard";

import { ToastContainer, toast } from 'react-toastify';

const Billing = ({ userData, scanData, planData, instantPlans }) => {

    // const [plans, setPlans] = useState([]);
    const [userPlan, setUserPlan] = useState({"name": "Newbie"});
    // const [scans, setScans] = useState([]);
    const [overallSecScore, setOverallSecScore] = useState(0);
    const [formattedScans, setFormattedScans] = useState([]);
    const [totFindings, setTotFindings] = useState(0);
    const [nextPayment, setNextPayment] = useState("N/A");

    const [cookies] = useCookies(["cookie-name"]);
    const navigate = useNavigate();
    const firebaseEASM = 'https://firebasestorage.googleapis.com/v0/b/hackplaza-40bf8.appspot.com/o/Sample-Reports%2FSurface%20Management.pdf?alt=media&token=3e7f7994-00af-4812-8a41-89890d153f4e'

    const axiosInstance = axios.create({
      baseURL: "https://api.cybersanctus.com/api",
    });

    useEffect(() => {
        if(userData.data.user.purchasedPackage){
          identifyPlan(userData.data.user.purchasedPackage.package);
        }
    }, [planData])

    useEffect((e) => {
      let customArray = [];
      let tempFindings = 0;
      let cumulativeSecScore = 0;
      scanData.map((scan) => {
        tempFindings = tempFindings + scan.vulns.length;
        let localSecScore = 0;
  
        const date = new Date(scan.createdAt);
        const formattedDate = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);
  
        scan.vulns.map((vuln) => {
          // //hidden console.log(vuln)
          // IMPLEMENT LOGIC TO CALCULATE SECURITY SCORE
          if(vuln.rating === "High"){
            cumulativeSecScore = cumulativeSecScore + 8;
            localSecScore = localSecScore + 8;
          } else if(vuln.rating === "Medium"){
            cumulativeSecScore = cumulativeSecScore + 4;
            localSecScore = localSecScore + 4;
          } else{
            cumulativeSecScore = cumulativeSecScore + 2;
            localSecScore = localSecScore + 2;
          }
        })
  
        customArray.push({
          name: 'default scan',
          date: formattedDate,
          lines: scan.lines,
          id: scan._id.slice(-4),
          score: 10 - (localSecScore / scan.vulns.length),
          report: scan.docUrl
        });
      })
      setFormattedScans(customArray);
      setTotFindings(tempFindings);
      setOverallSecScore(10 - (cumulativeSecScore / tempFindings));
    }, [scanData])

    useEffect(() => {
      if(userData.data.user.purchasedPackage) {
        const dateObj = new Date(userData.data.user.purchasedPackage.nextPaymentDue);
        const day = dateObj.getDate();
        const month = dateObj.toLocaleDateString('en-US', { month: 'long' });
        setNextPayment(`${day} ${month}`)
      }
    }, [userData])
    
      const identifyPlan = async (planid) => {
        if(planData){
          planData.map((plan) => {
            if(planid === plan._id){
              setUserPlan(plan);
            }
          })
        }
      }

      const cancelSubscription = async (e) => {
        try{
            console.log(cookies.jwt);
            toast.info("Cancelling Subscription...");
            const url = '/v1/payments/cancel';
            const { data } = await axiosInstance.post(
                url,
                // {
                //   userId: userData._id
                // },
                {
                    // headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")}
                    headers: { authorization: `Bearer ${cookies.jwt}`, 'Access-Control-Allow-Origin': "*" }
                }
            );
        }
        catch(error){
						console.log(error)
            toast.error("Unable to cancel subscription. Please contact support.")
        }
      }

    return (
        <div>
          {/* Card widget */}
    
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
                <Widget
                    icon={<MdBarChart className="h-7 w-7" />}
                    title={"Subscription"}
                    subtitle={userPlan.name}
                />
                <Widget
                    icon={<PiContactlessPaymentFill className="h-7 w-7" />}
                    title={"Next Payment"}
                    subtitle={nextPayment}
                />
                <Widget
                  icon={<MdDataUsage className="h-6 w-6" />}
                  title={"Montly Lines"}
                  subtitle={userData.data.user.purchasedPackage ? userData.data.user.purchasedPackage.remainingLines : "N/A"}
                />
                <Widget
                  icon={<IoFlash className="h-6 w-6" />}
                  title={"Instant Lines"}
                  subtitle={userData.data.user.instantLines}
                />
            </div>
    
          {/* Charts */}
    
          {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
            <TotalSpent />
            <WeeklyRevenue />
          </div> */}
    
            <div className="mt-5 grid-cols-1 gap-5">
                <Banner bannerSize="Large" />
            </div>
    
            {/* Tables & Charts */}

            {/* NFt Header */}
            <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-col md:items-left">
                <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
                    Monthly Plans
                </h4>
                <p className="ml-1 text-base text-navy-700 dark:text-gray-500">Find the right monthly package for you or your business.</p>
            </div>
    
            <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-3">

                {
                planData.map((plan) => (
                    <PricingCard plan={plan} userPlan={userPlan} type="monthly" />
                ))
                }
            </div>

            {/* NFt Header */}
            <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-col md:items-left">
                <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
                    Instant Refills
                </h4>
                <p className="ml-1 text-base text-navy-700 dark:text-gray-500">Need more lines of code? Instantly accredit your account with some lines of code available to scan.</p>
            </div>

            <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-4">

                {
                instantPlans.instantPlans.map((plan) => (
                    <PricingCard plan={plan} type="instant" />
                ))
                }
            </div>

            <div className="flex justify-center py-5">
              <button onClick={() => cancelSubscription()} className="rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-red-400 dark:hover:bg-red-300/10 dark:active:bg-red-200/10">
                Cancel Subscription
              </button>
            </div>

        </div>
      );
};

export default Billing;
