import TeamTable from "./components/TeamTable";
import { columnsDataComplex } from "./variables/columnsData";
import tableDataComplex from "./variables/tableDataComplex.json";


const Team = () => {
  return (
    <div>

        {/* NFt Header */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
            <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Manage Team
            </h4>
            <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
                <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
                >
                Docs
                </a>
            </li>
            <li>
                <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
                >
                Video
                </a>
            </li>
            <li>
                <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
                >
                Blog
                </a>
            </li>
            <li>
                <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
                >
                <a href=" ">Contact</a>
                </a>
            </li>
            </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-1">
            <TeamTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
            paginationBar={true}
            />
        </div>
        {/* <TeamTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
            paginationBar={true}
        /> */}
    </div>
  );
};

export default Team;
