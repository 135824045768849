import { useDisclosure } from "@chakra-ui/hooks";
import React, { useRef, useState, useEffect } from 'react';
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "../default/components/Storage";
import Upload from "./components/Upload";
import axios from 'axios';
import { useCookies } from "react-cookie";
import Card from "components/card";



const InformationalOverview = ({ userData, scanData, planData }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = useRef()
  const [cookies] = useCookies(["cookie-name"]);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          {/* <Banner /> */}
          <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
            <div className="mt-2 mb-8 w-full">
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                How to Upload
              </h4>
              <p className="mt-2 px-2 text-base text-gray-600">
              Our tool currently accepts ZIP archives containing either single Solidity file(s) or project files for Hardhat or Truffle projects. To ensure smooth processing:
              <ul className="list-disc px-5 pt-5">
                  <li>Single Solidity Files: Ensure your file includes one pragma solidity statement to specify the Solidity version. Note that external dependencies are not supported and may cause the compiler to fail.</li>
                  <li>Project Uploads: If you're uploading a Hardhat or Truffle project, include all necessary project files. The tool detects whether the upload is a project or standalone code by checking for a package.json file. This file is essential for the correct analysis of your project.</li>
                  <li>Simply compress your files into a ZIP archive and upload them. The tool will automatically begin analyzing the code upon upload.</li>
              </ul>
              </p>
            </div>
          </Card>
        </div>
        <div className="col-span-3 lg:!mb-0">
          <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
            <div className="mt-2 mb-8 w-full">
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                Beta Restrictions
              </h4>
              <p className="mt-2 px-2 text-base text-gray-600">
              As this tool is currently in beta, there are a few important restrictions to note:

              <ul className="list-disc px-5 pt-5 space-y-4">
                  <li>Mac User Issues: Some Mac users may experience difficulties when uploading ZIP files. If you encounter any problems, please contact our support team for assistance.</li>
                  <li>Occasional Failures: The tool may occasionally fail due to unforeseen issues. We appreciate your patience and encourage you to reach out to support if you face any challenges.</li>
              </ul>
              </p>
            </div>
          </Card>
        </div>

        <div className="z-0 col-span-5 lg:!mb-0">
          <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
            <div className="mt-2 mb-8 w-full">
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                Coming Soon
              </h4>
              <p className="mt-2 px-2 text-base text-gray-600">
              We are excited to announce several upcoming features that will enhance your experience:

              <ul className="list-disc px-5 pt-5 space-y-4">
                  <li>Collaborative Spaces: Invite co-workers to join your space or organization for more collaborative analysis.</li>
                  <li>GitHub Integration: Soon, you’ll be able to connect to your GitHub repositories directly, allowing you to fetch and scan code from your projects with ease.</li>
                  <li>Additional Enhancements: Stay tuned for more features designed to improve your experience and expand the tool's capabilities.</li>
                  <li>Trained AI: The AI model is currently untrained as GPT 4o is in use, which may result in unreliable or inconsistent information. We are actively working on training our own AI model to improve accuracy.</li>
              </ul>
              </p>
            </div>
          </Card>
        </div>
      </div>
      {/* all project & ... */}

      <div className="w-ful mt-2 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-6 lg:!mb-0">
          {/* <Banner /> */}
          <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
            <div className="mt-3 mb-8 w-full">
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                What to Expect
              </h4>
              <p className="mt-2 px-2 text-base text-gray-600">
              After submitting your Solidity code or project, the tool will generate a detailed PDF report. This report will outline any identified vulnerabilities, explaining their potential impact and the specific areas in your code that need attention. You’ll also receive tailored remediation steps to help you address these issues effectively.

The report is designed to be user-friendly, offering insights whether you're an experienced developer or just starting with Solidity. While the AI assists in generating these insights, it's recommended to have a security expert review the findings for accuracy before implementing any changes.
              </p>
            </div>
          </Card>
        </div>
        <div className="col-span-6 lg:!mb-0">
          <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
            <div className="mt-2 mb-8 w-full">
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                Disclaimer
              </h4>
              <p className="mt-2 px-2 text-base text-gray-600">
              This tool is intended strictly for educational and development purposes, not for military use or any malicious activities. It is provided "as-is," without any warranty, meaning the information and recommendations may not always be accurate or complete. Users should review and verify all findings before making changes.

As a beta product, the tool may have limitations or occasionally fail, especially for Mac users. We encourage users to contact support if they encounter issues. By using this tool, you agree to these terms and acknowledge that it is a supplementary tool, not a replacement for comprehensive code review processes.
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default InformationalOverview;
