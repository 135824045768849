import BarChart from "components/charts/BarChart";
// import { barChartDataDailyTraffic } from "variables/charts";
// import { barChartOptionsDailyTraffic } from "variables/charts";
import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";
import React, { useState, useEffect } from "react";


const DailyTraffic = ({ scanData }) => {

  const getMonthNames = () => ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [monthlyScans, setMonthlyScans] = useState([0, 0, 0, 0, 0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [percentageChange, setPercentageChange] = useState(null);

  useEffect(() => {
    countScansPerMonth();
    if (monthlyScans.length > 1) {
      const change = calculatePercentageChange(monthlyScans[5], monthlyScans[4]);
      setPercentageChange(change.toFixed(2));
    } else {
      setPercentageChange(null);
    }
  }, [])

  const getPast6Months = (currentMonth) => {
    const months = getMonthNames();
    let past6Months = [];
    for (let i = 5; i >= 0; i--) {
      past6Months.push(months[(currentMonth - i + 12) % 12]);
    }
    return past6Months;
  };

  const countScansPerMonth = () => {

    //hidden console.log(scanData)
    setIsLoaded(false);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
  
    let counts = new Array(6).fill(0);
  
    scanData.forEach(scan => {
      let scanDate = new Date(scan.createdAt);
      let scanMonth = scanDate.getMonth();
      let scanYear = scanDate.getFullYear();
  
      let monthDiff = (currentYear - scanYear) * 12 + (currentMonth - scanMonth);
      if (monthDiff >= 0 && monthDiff < 6) {
        counts[5 - monthDiff]++;
      }
    });

    setMonthlyScans(counts);
    setIsLoaded(true);
    // return counts;
  };
  
  const currentMonth = new Date().getMonth();
  const monthNames = getPast6Months(currentMonth);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) {
      return current === 0 ? 0 : 100;
    }
    return ((current - previous) / previous) * 100;
  };

  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000"
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: monthNames,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              // color: "#4318FF",
              color: "#fc1e76",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(67, 24, 255, 1)",
              // color: "fc825c",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  };
  
  return (
    isLoaded ? 
    
      <Card extra="pb-7 p-[20px]">
        <div className="flex flex-row justify-between">
          <div className="ml-1 pt-2">
            <p className="text-sm font-medium leading-4 text-gray-600">
              Monthly Scans
            </p>
            <p className="text-[34px] font-bold text-navy-700 dark:text-white">
              {/* 12{" "} */}
              {monthlyScans[5]}{" "}
              <span className="text-sm font-medium leading-6 text-gray-600">
                {/* avg. monthly */}
                This Month
              </span>
            </p>
          </div>
          <div className="mt-2 flex items-start">
            <div className="flex items-center text-sm text-green-500">
              <MdArrowDropUp className="h-5 w-5" />
              {/* <p className="font-bold"> +2.45% </p> */}
              <p className="font-bold"> {percentageChange}% </p>
            </div>
          </div>
        </div>

        <div className="h-[300px] w-full pt-10 pb-0">
          <BarChart
            chartData={[
              {
                name: "Monthly Scans",
                data: monthlyScans,
              },
            ]}
            chartOptions={barChartOptionsDailyTraffic}
          />
        </div>
      </Card>

      :

      'Loading'
    
  );
};

export default DailyTraffic;
