import React, { useState, useEffect } from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import profileBanner from "assets/img/profile.png";
import Card from "components/card";

const Banner = ({ userData, planData, scanData, secScore }) => {

  const [userPlan, setUserPlan] = useState({"name": "Newbie"});

  //hidden console.log(planData)

  const identifyPlan = async (planid) => {
    if(planData){
      planData.map((plan) => {
        if(planid === plan._id){
          setUserPlan(plan);
        }
      })
    }
  }

  useEffect(() => {
    if(userData.data.user.purchasedPackage){
      identifyPlan(userData.data.user.purchasedPackage.package);
    }
  }, [planData])

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${profileBanner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={userData.data.user.profilePic} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {userData.data.user.email.split('@')[0]}
        </h4>
        <p className="text-base font-normal text-gray-600">{userData.data.user.email}</p>
      </div>

      {/* Post followers */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">{userPlan.name}</p>
          <p className="text-sm font-normal text-gray-600">Plan</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {scanData.length}
          </p>
          <p className="text-sm font-normal text-gray-600">Scans</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {secScore}
          </p>
          <p className="text-sm font-normal text-gray-600">Avg. Score</p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
